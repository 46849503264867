import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { afterNextRender, ChangeDetectorRef, Component, Inject, PLATFORM_ID } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { NavigationEnd, Router, Event, RouterModule } from '@angular/router';
import { environment } from 'src/environments/environment';
import { UserData } from './_utils/user-data';
import { UsuarioService } from 'src/app/_services/usuario.service';
import { SeoService } from 'src/app/_services/seo.service';
import { HeaderComponent } from 'src/app/header/header.component';
import { CabeceraBlastComponent } from 'src/app/blast/shared/cabecera-blast/cabecera-blast.component';
import { FooterComponent } from 'src/app/footer/footer.component';
import { FooterBlastComponent } from 'src/app/blast/shared/footer-blast/footer-blast.component';
import { SharedModule } from 'src/app/_shared/_shared.module';
import { BtnHomeFloatingComponent } from 'src/app/_shared/bloque-buttons/btn-home-floating/btn-home-floating.component';
import { PlatformService } from 'src/app/_services/platform.service';
import { CabeceraPlataformaErroneaComponent } from 'src/app/header/cabecera-plataforma-erronea/cabecera-plataforma-erronea.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [
    HeaderComponent,
    CabeceraBlastComponent,
    CabeceraPlataformaErroneaComponent,
    BtnHomeFloatingComponent,
    FooterComponent,
    FooterBlastComponent,
    RouterModule,
    SharedModule,
  ]
})
export class AppComponent {
  title = 'Grupo SEGOFINANCE';
  version = "8.13.11";
  isAppStable: boolean = false;
  isLogged: boolean = false;
  isContactarHidden: boolean = false;
  isContactarClosed: boolean = false;
  tipoCabecera: string;
  private userData: UserData;

  constructor(
    @Inject(PLATFORM_ID) private _platformBrowserId: Object,
    @Inject(DOCUMENT) private _document: Document,
    private _router: Router,
    private _platformService: PlatformService,
    private _usuarioservice: UsuarioService,
    private _seoService: SeoService,
    private _titleService: Title,
    private _metaService: Meta,
    private _cdr: ChangeDetectorRef,
  ) {
    this.buildApp();
  }

  private buildApp(): void {
    this._platformService.setPlatformEnvironment(this._document.location.origin);
    console.log(`prod:${environment.production} - version${this.version} - ${environment.linea}`)

    this._router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        if (isPlatformBrowser(this._platformBrowserId)) {
          this.scrollTop();
          this.setSeo();
          this.setMetas();
          this.getSession();
          this.getHeader();

          if(environment.production){
            // Send page view event to dataLayer
            const dataLayer = window['dataLayer'] || [];
            dataLayer.push({
              event: 'page_view',
              page_path: event.urlAfterRedirects
            });
          }
        }
      }
    })

    // Evita problemas de hydratation al hacer llamadas a servicios (rxjs, error de timeout 30 seconds al cargar la app)
    afterNextRender(() => {
      this.isAppStable = true;
      this._cdr.detectChanges();
    });
  }

  private scrollTop(): void {
    const route = this._router.url;
    if (!route.includes("indexadas") && !route.includes("tematicas")) {
      //Sin el setTimeout no funciona bien ya que hace el scroll antes de que se haya renderizado la página
      setTimeout(() => { window.scrollTo({ top: 0, behavior: 'instant' }) }, 400);
    }
  }

  private getHeader(): void {

    if (this._router.url.includes("/blast/")) {
      this.tipoCabecera = "blast";
    }
    else if (this._router.url.includes("landings/acuerdo-privacidad")) {
      this.tipoCabecera = "my-investor";
    }
    else if (this._router.url.includes("/creative") && !this._router.url.includes("/legal/") && !this._router.url.includes("/creative/registro")) {
      this.tipoCabecera = "creative";
    }
    else if (this._router.url.includes("/mantenimiento")) {
      this.tipoCabecera = "mantenimiento";
    }
    else if (this._router.url.includes("/login/plataforma-erronea")) {
      this.tipoCabecera = "plataforma-erronea";
    }
    else if (this._router.url.includes("/info-creative")) {
      this.tipoCabecera = "info-creative";
    }
    else if (this._router.url.includes("/creative/registro")) {
      const urlParams = new URLSearchParams(this._router.url.split('?')[1]);
      if (urlParams.get('returnUrl') === '/info-creative') {
        this.tipoCabecera = "info-creative";
      }
    }
    else {
      this.tipoCabecera = "web-sego";
    }
  }

  private getSession(): void {
    if (this._usuarioservice.getSession()) {
      this.userData = new UserData();
      this.userData.username = this._usuarioservice.getSession().siglas;
      this.isLogged = true;
    }
    else {
      this.isLogged = false;
    }
  }

  private setSeo(): void {
    const routeName = this._router.url.split("?")[0].replace(/\//g, "_").replace(/-/g, "_");
    if (document.getElementById("canonical")) {
      document.head.removeChild(document.getElementById("canonical"))
    }
    const link = document.createElement("link");
    link.id = "canonical";
    link.rel = "canonical";
    //TODO que pasa si el link está vacío, he puesto el "?", porque sino explota
    link.href = this._seoService.canonicas[routeName]?.link;
    document.head.appendChild(link);
  }

  private setMetas(): void {
    const routeName = this._router.url.split("?")[0].replace(/\//g, "_").replace(/-/g, "_");
    const pathStrings = this._router.url.split("/");
    let metaInfo = this._seoService.data[routeName];

    if (pathStrings[1] == 'realestate' || pathStrings[1] == 'venture') {
      metaInfo = this._seoService.data['_proyecto'];
    }
    else if (metaInfo && pathStrings[1] != 'proyecto') {
      this.updateMetasPagina(metaInfo);
    }
    else {
      this._titleService.setTitle("SegoFinance");
    }
    if (metaInfo) {
      this.updateMetasPagina(metaInfo);
    }
    else {
      this._titleService.setTitle("Inversión alternativa | Oportunidades de inversión | SEGO");
    }
  }

  private updateMetasPagina(metaInfo: any): void {
    this._titleService.setTitle(metaInfo.title)
    metaInfo.metaTags.forEach(m => {
      this._metaService.removeTag(`name="${m.name}"`)
      this._metaService.removeTag(`property="${m.property}"`)
      if (m.property) {
        this._metaService.addTags([
          { property: m.property, content: m.content },
        ])
      }
      else {
        this._metaService.addTags([
          { name: m.name, content: m.content },
        ])
      }
    });
  }

  closeContactar(): void {
    this.isContactarClosed = true;
  }
}
